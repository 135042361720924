import React from "react";
import logo from "../assets/images/logo_mk.svg";
import "../styles/navigation_styles.css";


export default function Navigation(){
    return (
        <>
            <nav className="navbar">
                <img src={logo} alt="logo" className="logo_top"/>
                <ul className="navList">
                    <li className="navItem"><a href="#portfolio" className="link">Portfolio</a></li>
                    <li className="navItem"><a href="#about" className="link">About</a></li>
                    <li className="navItem"><a href="#resume" className="link">Resume</a></li>
                    <li className="navItem"><a href="#contact" className="link">Contact</a></li>
                    <li className="navItem"><a href="https://github.com/marekksiazek" className="link">Github</a></li>
                    <li className="navItem"><a href="https://www.linkedin.com/in/marek-książek-4a6947180" className="link">Linkedin</a></li>
                </ul>
            </nav>
            <nav className="navbarPhone">
                <input type="checkbox" class="openSidebarMenu" id="openSidebarMenu" />
                <label for="openSidebarMenu" class="sidebarIconToggle">
                    <div class="spinner diagonal part-1"></div>
                    <div class="spinner horizontal"></div>
                    <div class="spinner diagonal part-2"></div>
                </label>
                <div id="sidebarMenu">
                    <ul class="sidebarMenuInner">
                        <li><a href="#portfolio">Portfolio</a></li>
                        <li><a href="#about">About</a></li>
                        <li><a href="#resume">Resume</a></li>
                        <li><a href="#contact">Contact</a></li>
                        <li><a href="https://github.com/marekksiazek" >Github</a></li>
                        <li><a href="https://www.linkedin.com/in/marek-książek-4a6947180" >Linkedin</a></li>
                    </ul>
                </div>
            </nav>
        </>
            
    );
}